/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
import Settings from './Settings';
var dateFormat = require('dateformat');

var auth = () => {
  const _token = localStorage.getItem('token')
  if (_token) {
    const token = JSON.parse(localStorage.getItem('token'))
    return token.token_type + ' ' + token.access_token
  }
  return ''
}

const Utility = {

  getAsync: async (url) => {
    var headers = new Headers();

    headers.append('Authorization', auth())

    let response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
      redirect: 'manual',
      headers: headers
    });

    let data = await response.json();
    return {
      data,
      status_code: response.status
    };
  },
  // 
  deleteAsync: async (url) => {
    var headers = new Headers();
    headers.append('Authorization', auth())

    let response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'omit',
      redirect: 'manual',
      headers: headers
    });

    return {
      data: response.status == 204 ? {} : await response.json(),
      status_code: response.status
    };
  },
  postAsync: async (url, formData) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    const response = await fetch(new Request(url, { redirect: 'manual' }), {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).catch((e) => {
      return false;
    });

    const data = await response.json();
    return {
      data: data,
      status_code: response.status
    }
  },
  putAsync: async (url, formData) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    const response = await fetch(new Request(url, { redirect: 'manual' }), {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).catch((e) => {
      return false;
    });

    const data = await response.json();
    return {
      data: data,
      status_code: response.status
    }
  },
  patchAsync: async (url, formData) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    const response = await fetch(new Request(url, { redirect: 'manual' }), {
      method: 'PATCH',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).catch((e) => {
      return false;
    });

    const data = await response.json();
    return {
      data: data,
      status_code: response.status
    }
  },

  patchAsyncFormdata: async (url, formData) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth())
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    const response = await fetch(new Request(url, { redirect: 'manual' }), {
      method: 'PATCH',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(formData)
    }).catch((e) => {
      return false;
    });

    const data = await response.json();
    return {
      data: data,
      status_code: response.status
    }
  },

  postUpload: (url, formData, cb) => {

    var headers = new Headers();
    //headers.append('Content-Type', "multipart/form-data");
    headers.append('Authorization', auth())

    var status = 200
    fetch(new Request(url, { redirect: 'manual' }), {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: formData
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {

      if (status == 201)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, {}, status);
    });
  },

  postUploadAsync: async (url, formData) => {

    var headers = new Headers();
    //headers.append('Content-Type', "multipart/form-data");
    headers.append('Authorization', auth())

    var status = 200
    const response = await fetch(new Request(url, { redirect: 'manual' }), {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: formData
    }).catch((e) => {
      return false;
    });

    const data = await response.json();
    return {
      data: data,
      status: response.status == 201
    }
  },

  putUpload: (url, formData, cb) => {

    var headers = new Headers();
    //headers.append('Content-Type', "multipart/form-data");
    headers.append('Authorization', auth())

    var status = 200
    fetch(new Request(url, { redirect: 'manual' }), {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: formData
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if (status == 200)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, {}, status);
    });
  },
  patchUpload: (url, formData, cb) => {

    var headers = new Headers();
    //headers.append('Content-Type', "multipart/form-data");
    headers.append('Authorization', auth())

    var status = 200
    fetch(new Request(url, { redirect: 'manual' }), {
      method: 'PATCH',
      mode: 'cors',
      headers: headers,
      body: formData
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if (status == 200)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, {}, status);
    });
  },
  postAuth: (url, formData, cb) => {

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    var status = 200
    fetch(new Request(url, { redirect: 'manual' }), {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if ([200, 201].includes(status))
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, {}, status);
    });
  },
  postJson: (url, formData, cb) => {

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth())
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    var status = 200
    fetch(new Request(url, { redirect: 'manual' }), {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(formData)
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if (status == 201)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, {}, status);
    });
  },
  post: (url, formData, cb) => {
    Utility.postAuth(url, formData, cb);
    return;
  },
  postBasic: (url, formData, cb) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    let status = 200
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      cb(true, resJson, status);
    }).catch((e) => {
      cb(false, { error: e }, status);
    });
  },
  put: (url, formData, cb) => {

    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    var status = 200
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())
    fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if (status == 200)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, { error: e }, status);
    });
  },
  putJson: (url, formData, cb) => {

    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    var status = 200
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', auth())
    fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(formData)
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if (status == 200)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, { error: e }, status);
    });
  },

  patch: (url, formData, cb) => {

    for (let k in formData) {
      if (formData[k] == 'null' || formData[k] == null)
        delete formData[k]
    }
    var status = 200
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())
    fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: headers,
      body: Utility.jsonToQueryString(formData)
    }).then((response) => {
      status = response.status
      return response.json()
    }).then((resJson) => {
      if (status == 200)
        cb(true, resJson, status);
      else {
        cb(false, resJson, status);
      }
    }).catch((e) => {
      cb(false, { error: e }, status);
    });
  },
  delete: (url, cb) => {

    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Authorization', auth())

    var status = 204
    fetch(url, {
      method: 'delete',
      mode: 'cors',
      headers: headers
    }).then((response) => {
      status = response.status
      if (status == 204)
        cb(true, {}, status);
      else
        return response.json()
    }).then((resJson) => {
      cb(false, resJson, status);
    }).catch((e) => {
      cb(status == 204, {}, status);
    });
  },
  get: (url, cb) => {

    var headers = new Headers();
    headers.append('Authorization', auth())
    var status = 200
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
      redirect: 'manual',
      headers: headers
    }).then((response) => {
      status = response.status
      return response.json()

    }).then((resJson) => {
      cb(status == 200, resJson, status);
    }).catch((e) => {
      cb(false, e, status);
    });
  }, options: (url, cb) => {

    var headers = new Headers();
    headers.append('Authorization', auth())
    var status = 200
    fetch(url, {
      method: 'OPTIONS',
      headers: headers
    }).then((response) => {
      status = response.status
      return response.json()

    }).then((resJson) => {
      cb(status == 200, resJson, status);
    }).catch((e) => {
      cb(false, e, status);
    });
  },
  getNoCors: (url, cb) => {

    var headers = new Headers();
    fetch(url, {
      method: 'GET'
    }).then((response, type, type2) => {
      cb(true, {});
    }).catch((e) => {
      cb(false, {});
    });
  },
  getAuth: (url, cb) => {
    var headers = new Headers();
    headers.append('Authorization', auth())
    fetch(new Request(url, { redirect: 'manual' }), {
      method: 'GET', mode: 'cors',
      headers: headers
    }).then((response) => response.json()).then((resJson) => {
      cb(true, resJson);
    }).catch((e) => {
      cb(false, {});
    });
  },
  getFetchAuth: (url) => {

    var headers = new Headers();
    headers.append('Authorization', auth())
    headers.append('Content-Type', "application/x-www-form-urlencoded");
    return fetch(new Request(url, { redirect: 'follow' }), {
      method: 'GET',
      mode: 'cors',
      headers: headers
    }).then(function (response) {
      return response.json()
    });
  },
  getFetch: (url) => {

    var headers = new Headers();
    headers.append('Authorization', auth())
    headers.append('Content-Type', "application/x-www-form-urlencoded");
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: headers
    }).then(function (response) {
      return response.json()
    });
  },
  getCounter: (model, cb) => {

    var headers = new Headers();
    headers.append('Authorization', auth())
    headers.append('Content-Type', "application/x-www-form-urlencoded");
    fetch(Settings.baseUrl + '/counter/?model=' + model, {
      method: 'GET',
      mode: 'cors',
      headers: headers
    }).then((response) => response.json()).then((resJson) => {
      cb(true, resJson);
    }).catch((e) => {
      cb(false, {});
    });
  },
  getNumberLeng: function (n, leng) {
    var c = n + '';
    var r = c;
    for (var i = c.length; i < leng; i++) {
      r = '0' + r;
    }
    return r;
  },
  formatDate: function (date) {
    let d = new Date(date);
    return dateFormat(d, "dd/mm/yyyy");
  },
  formatDate3: function (date) {
    let d = new Date(date);
    return dateFormat(d, "dd-mm-yyyy");
  },
  formatTime: function (date) {
    let d = new Date(date);
    return dateFormat(d, "HH:MM");
  },
  formatTimeSecond: function (date) {
    let d = new Date(date);
    return dateFormat(d, "HH:MM:ss");
  },
  formatMonthYear: function (date) {
    if (!date)
      return ''
    let d = new Date(date);
    return dateFormat(d, "mm/yyyy");
  },
  formatYear: function (date) {
    let d = new Date(date);
    return dateFormat(d, "yyyy");
  },
  getObject: function (arr, val) {
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].id) == parseInt(val)) {
        return arr[i];
      }
    }
  },
  getOptions: function (arr, value = 'id', text = 'name') {
    let options = []
    arr.map((item, i) => {
      options.push({
        value: item[value],
        key: i,
        text: item[text]
      })
    })
    return options
  },
  getObjectByValue: function (arr, val) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value == val) {
        return arr[i];
      }
    }
  },
  formatDate2: function (date) {

    let d = new Date(date);
    return dateFormat(d, "yyyy-mm-dd");
  },
  formatDateTime: function (date) {

    let d = new Date(date);
    return dateFormat(d, "dd/mm/yyyy HH:MM");
  },
  formatDateViewToDate: function (string_date) {
    let d = string_date.split('/');
    return d[2] + "-" + d[1] + "-" + d[0];
  },
  priceFormat: function (amount) {

    if (amount == null || amount == '' || isNaN(Number(amount)))
      return '0.00'

    try {
      return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }, numberFormat: function (amount) {

    try {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return parseInt(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  removeCommas: function (str) {
    str = str + ""
    if (str)
      while (str.search(",") >= 0) {
        str = (str + "").replace(',', '');
      }
    return str;
  },
  weightFormat: function (amount) {
    if (amount == null || amount == '' || isNaN(Number(amount)))
      return '0.000'
    try {
      return amount.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      return parseFloat(amount).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  sumPrice: function (arr, isSell) {
    let ret = {
      totalPrice: 0,
      netPrice: 0
    };
    let price = 0;

    for (let i = 0; i < arr.length; i++) {
      price = isSell ? Number(Utility.removeCommas(arr[i].sellPrice)) : Number(Utility.removeCommas(arr[i].buyPrice))
      ret.totalPrice += price;
      ret.netPrice += price;
    }

    return ret;
  },
  sumWeight: function (arr, isSell) {
    let ret = {
      totalPrice: 0,
      netPrice: 0
    };
    let price = 0;

    for (let i = 0; i < arr.length; i++) {
      price = isSell ? Number(Utility.removeCommas(arr[i].sellPrice)) : Number(Utility.removeCommas(arr[i].buyPrice))
      ret.totalPrice += price;
      ret.netPrice += price;
    }

    return ret;
  },
  jsonToQueryString: function (json) {
    return Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
  },
  cloneObjectJson: function (o) {
    return Object.assign({}, o)
  },
  arabicNumberToText: (Number) => {
    var NumberArray = ["ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ"]
    var DigitArray = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"]
    var BahtText = "";
    if (isNaN(Number)) {
      return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
      if ((Number - 0) > 9999999.9999) {
        return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
      } else {
        Number = Number.split(".");
        if (Number[1].length > 0) {
          Number[1] = Number[1].substring(0, 2);
        }
        var NumberLen = Number[0].length - 0;
        for (var i = 0; i < NumberLen; i++) {
          var tmp = Number[0].substring(i, i + 1) - 0;
          if (tmp != 0) {
            if ((i == (NumberLen - 1)) && (tmp == 1)) {
              BahtText += "เอ็ด";
            } else
              if ((i == (NumberLen - 2)) && (tmp == 2)) {
                BahtText += "ยี่";
              } else
                if ((i == (NumberLen - 2)) && (tmp == 1)) {
                  BahtText += "";
                } else {
                  BahtText += NumberArray[tmp];
                }
            BahtText += DigitArray[NumberLen - i - 1];
          }
        }
        BahtText += "บาท";
        if ((Number[1] == "0") || (Number[1] == "00")) {
          BahtText += "ถ้วน";
        } else {
          let DecimalLen = Number[1].length - 0;
          for (var i = 0; i < DecimalLen; i++) {
            var tmp = Number[1].substring(i, i + 1) - 0;
            if (tmp != 0) {
              if ((i == (DecimalLen - 1)) && (tmp == 1)) {
                BahtText += "เอ็ด";
              } else
                if ((i == (DecimalLen - 2)) && (tmp == 2)) {
                  BahtText += "ยี่";
                } else
                  if ((i == (DecimalLen - 2)) && (tmp == 1)) {
                    BahtText += "";
                  } else {
                    BahtText += NumberArray[tmp];
                  }
              BahtText += DigitArray[DecimalLen - i - 1];
            }
          }
          BahtText += "สตางค์";
        }
        return BahtText;
      }
    }
  },
  dateThai: (date) => {
    var monthNamesThai = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
      "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    let d = new Date(date);

    return dateFormat(d, "dd") + '/' + monthNamesThai[d.getMonth()] + '/' + (d.getFullYear() + 543);
  },
  pad: (num, size) => {
    num = num + ""
    var s = size + num;
    return s.substr(num.length);
  },
  filterObject: (lists) => {


    let _lists = []
    for (let k in lists) {

      if (lists[k].is_enabled == 1)
        _lists.push(lists[k])
    }

    return _lists;
  },
  filterSearch: (lists) => {
    let list2 = {}
    for (let k in lists) {
      if (lists[k] != 0)
        list2[k] = lists[k]
    }
    return list2;
  },
  setOptionFetch: (response, choices_name, _default = null) => {

    let choices = []
    let _ch = response.actions.POST[choices_name].choices
    if (_default != null) {
      choices.push(_default)
    }
    for (let i in _ch) {
      choices.push({
        value: _ch[i].value,
        key: _ch[i].value,
        text: _ch[i].display_name,
      })
    }
    return choices
  },

  parseInt: (value, value_old = 0, min = 0, max = null) => {
    const num = parseInt(value)
    const _value_old = Number(value_old)
    if (isNaN(num))
      return _value_old

    if (num < min) {
      return _value_old > min ? _value_old : min
    }
    if (max != null && num > max) {
      return max
    }

    return num
  },
  parseFloat: (value, value_old = 0, min = 0, max = null) => {
    const num = parseFloat(value)
    const _value_old = Number(value_old)
    if (isNaN(num))
      return _value_old

    if (num < min) {
      return _value_old > min ? _value_old : min
    }
    if (max != null && num > max) {
      return max
    }

    return num
  },
  getAge(birthdate) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    
    // คำนวณเวลาเป็นมิลลิวินาที
    const differenceInMilliseconds = today.getTime() - birthDate.getTime();
    
    // แปลงมิลลิวินาทีเป็นปี
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // รวมวันอธิกสุรทิน
    const age = Math.floor(differenceInMilliseconds / millisecondsInYear);
    
    return age;
  },
  roundUpToNext50(value) {
    return value % 50 === 0 ? value : Math.ceil(value / 50) * 50;
  },


};

export default Utility;
